<template>
  <v-container fluid>
    <v-row>
      <v-col class="text-center">
        <v-row justify="end" class="pt-3">
          <v-btn icon v-on:click="dialog_search = true">
            <v-icon :size="icon_size" class="mr-3 mt-3"
              >mdi-magnify</v-icon
            >
          </v-btn>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn  icon v-bind="attrs" v-on="on">
                <v-icon :size="icon_size" class="mr-5 mt-3"
                  >mdi-dots-vertical</v-icon
                >
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-on:click="logout">
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>

        <h1 :class="$vuetify.breakpoint.smAndDown ? '' : 'text-h3'">
          {{ current_name }}
        </h1>

        <v-icon x-large>mdi-account</v-icon>
        <br />
        <v-btn
          v-on:click="user_det(current_id)"
          color="blue"
          class="ma-3"
          text
          outlined
        >
          Editar Perfil</v-btn
        >
      </v-col>
    </v-row>
    <br />

    <v-card
      class="mx-auto rounded-t-lg blue lighten-4"
      :max-width="$vuetify.breakpoint.xl ? 900 : 700"
    >
      <v-card-title class="white--text blue darken-2">
        User Directory
        <v-btn
          v-if="allowed"
          color="white"
          height="48"
          width="48"
          class=" mr-n7 mt-n11"
          fab
          absolute
          right
          @click="show_add = true"
        >
          <v-icon color="blue">fas fa-plus</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-4"> Usarios DPEI </v-card-text>
      <v-card-text v-if="!allowed" class="pt-4 text-center">
        <h2>Not Authorized</h2>
      </v-card-text>

      <v-divider></v-divider>

      <v-virtual-scroll
        class="pb-6 mb-n10 blue lighten-5"
        :items="items"
        :item-height="60"
        :height="scroll_size"
      >
        <template v-slot:default="{ item }">
          <v-list-item v-on:click="user_det(item.user_id)">
            <v-list-item-avatar>
              <v-avatar :color="item.color" size="56" class="white--text">
                {{ item.initials }}
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ item.fullName }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn depressed small>
                {{ item.status }}
                <v-icon color="orange darken-4" right> mdi-account </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-card>

    <v-dialog v-model="show" width="1000">
      <v-card elevation="24" class="grey lighten-2" style=" border-radius: 12px;">
        <v-container fluid>
          <v-row>
            <v-col class="text-center py-2 pt-4">
              <v-row justify="end">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="delete_task_dialog = true">
                      <v-list-item-title>Eliminar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn absolute left icon class="ml-n3">
                  <v-icon size="20" @click="show = false">mdi-close</v-icon>
                </v-btn>
              </v-row>

              <h1>{{ name }} {{ surname }}</h1>

              <br />
              <v-icon x-large color="white"> mdi-account-circle </v-icon>
            </v-col>
          </v-row>

          <br />
          <div class="text-center" justify="center">
            <v-dialog v-model="dialog" width="350">
              <v-card class="text-center" style=" border-radius: 12px;">
                <v-card-title class="text-h4 grey justify-center">
                  Invalid
                </v-card-title>

                <v-card-text class="text-h6">
                  Invalid E-mail
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    style="margin-left: auto; margin-right: auto"
                    color="error"
                    outlined
                    text
                    @click="dialog = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

          <v-row style="height: 400px" justify="center" align="center">
            <v-col cols="md-7">
              <v-form v-model="valid">
                <v-text-field
                  v-model="name"
                  label="Name"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                  v-model="surname"
                  label="Surname"
                  outlined
                  required
                ></v-text-field>
                <v-select
                  :items="options"
                  label="Clearance"
                  v-model="status1"
                  outlined
                ></v-select>
                <v-text-field
                  v-model="email"
                  label="E-mail"
                  outlined
                  :rules="reglas_email"
                  required
                ></v-text-field>

                <div class="text-center">
                  <v-btn
                    :disabled="!valid"
                    class="mr-4"
                    color="error"
                    @click="show = false"
                  >
                    Cancelar
                  </v-btn>

                  <v-btn
                    :disabled="!valid"
                    class="mr-4"
                    color="blue darken-1"
                    v-on:click="save_info"
                  >
                    Guardar
                  </v-btn>
                </div>
              </v-form>
            </v-col>
          </v-row>

          <br />
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog persistent content-class="mt-n4" v-model="show_add" width="1000">
      <v-card elevation="24"  class="grey lighten-2" style=" border-radius: 12px;">
        <v-container fluid>
          <div class="Signup">
            <v-icon size="20" @click="show_add = false" class="mt-n2 "
              >mdi-close</v-icon
            >
            <div class="mt-n5" align="center">
              <h1>Nuevo Usuario</h1>
              <br />
              <v-icon x-large color="white"> mdi-account-circle </v-icon>
            </div>
            <div class="text-center" justify="center">
              <v-dialog v-model="dialog" width="350">
                <v-card class="text-center" style=" border-radius: 12px;">
                  <v-card-title class="text-h4 grey justify-center">
                    Invalid
                  </v-card-title>

                  <v-card-text class="text-h6">
                    Not a valid E-mail or already being used
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      style="margin-left: auto; margin-right: auto"
                      color="error"
                      outlined
                      text
                      @click="dialog = false"
                    >
                      Ok
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

            <v-row justify="center">
              <v-col cols="md-7">
                <v-form v-model="valid">
                  <v-text-field
                    v-model="name1"
                    label="Name"
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="surname1"
                    label="Surname"
                    outlined
                    required
                  ></v-text-field>
                  <v-select
                    :items="options1"
                    label="Clearance"
                    v-model="status1"
                    outlined
                  ></v-select>

                  <v-text-field
                    v-model="email1"
                    label="E-mail"
                    outlined
                    :rules="reglas_email"
                    required
                  ></v-text-field>
                  <v-row class="mx-0">
                    <v-text-field
                      @keydown.enter="add_user()"
                      v-model="password1"
                      label="Password"
                      outlined
                      :type="show_pass ? '' : 'password'"
                      required
                    ></v-text-field>
                    <v-btn
                      icon
                      class="mt-3 ml-n11"
                      @mousedown="show_pass = true"
                      @mouseup="show_pass = false"
                      v-touch="{
                        start: () => (show_pass = true),
                        end: () => (show_pass = false),
                      }"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </v-row>
                  <div class="text-center">
                    <v-btn
                      :disabled="!valid"
                      color="success"
                      v-on:click="add_user"
                      >Add User
                    </v-btn>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_search" width="800">
      <v-card class="grey lighten-2" style=" border-radius: 12px;">
        <v-card-title>
          Buscador
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="user_list" :search="search" class="grey lighten-2">
          <template v-slot:item.actions="{ item }">
            <v-btn
              @click="dialog_search = false"
              v-on:click="user_det(item._id)"
            >
              <v-icon small class="mr-2">
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_task_dialog" width="350">
      <v-card class="text-center " style=" border-radius: 12px;">
        <v-card-title class="text-h4 blue-grey justify-center">
          Eliminar
        </v-card-title>

        <v-card-text class="text-h6">
          ¿Está seguro de que desea eliminar este Usuario?
        </v-card-text>
        <v-card-actions>
          <v-btn
            style="margin-left: auto; margin-right: auto"
            color="blue darken-1"
            text
            @click="delete_task_dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            style="margin-left: auto; margin-right: auto"
            color="error"
            text
            @click="delete_task_dialog = false"
            v-on:click="delete_usr"
          >
            eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from "axios";

export default {
  name: "user",

  data: () => ({
    current_name: "",
    current_id: "",
    search: "",
    dialog_search: false,
    options1: ["Viewer", "Employee", "Manager", "Admin"],
    status1: "",
    clearance1: "",
    name1: "",
    surname1: "",
    delete_task_dialog: false,
    password1: "",
    email1: "",
    show_add: false,
    show_pass: false,
    show: false,
    user_info: [],
    options: ["Viewer", "Employee", "Manager", "Admin"],
    clearance: "",
    name: "",
    surname: "",
    id: "",
    password: "",
    headers: [
      {
        text: "Nombre",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Apellido", value: "surname" },
      { text: "Email", value: "email" },
      { text: "Editar", value: "actions", sortable: false },
    ],
    user_list: [],
    valid: true,
    dialog: false,
    email: "",
    reglas_email: [(v) => !!v || "Email is required"],
    all_users: [],
    allowed: true,
    colors: [
      "#2196F3",
      "#90CAF9",
      "#64B5F6",
      "#42A5F5",
      "#1E88E5",
      "#1976D2",
      "#1565C0",
      "#0D47A1",
      "#82B1FF",
      "#448AFF",
      "#2979FF",
      "#2962FF",
    ],
    names: [],
    surnames: [],
  }),
  mounted() {
    this.Getallusers();
    this.parseJwt();
  },

  computed: {
    icon_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 24;
        case "sm":
          return 24;
        case "md":
          return 30;
        case "lg":
          return 30;
        case "xl":
          return 30;
      }
    },
    scroll_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 350;
        case "sm":
          return 350;
        case "md":
          return 300;
        case "lg":
          return 400;
        case "xl":
          return 580;
      }
    },
    items() {
      for (let i = 0; i < this.names.length; i++) {
        if (this.names[i][2] == this.current_id) {
          this.names.splice(i, 1);
          this.surnames.splice(i, 1);
        } else {
        }
      }
      return Array.from({ length: this.names.length }, (k, v) => {
        const name = this.names[v][0];
        let status = this.names[v][3];
        let user_id = this.names[v][2];
        const surname = this.surnames[v];

        if (status == 0) {
          status = "admin";
        } else if (status == 1) {
          status = "Manager";
        } else if (status == 2) {
          status = "Employee";
        } else {
          status = "Viewer";
        }
        return {
          color: this.colors[this.genRandomIndex(this.colors.length)],
          fullName: `${name} ${surname}`,
          initials: `${name[0]} ${surname[0]}`,
          status: `${status}`,
          user_id: `${user_id}`,
        };
      });
    },
  },
  methods: {
    parseJwt(token = sessionStorage.getItem("token")) {
      if (token == null){
        window.location.replace("/login");
      }
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      this.auth_user = JSON.parse(jsonPayload);
      var date = new Date();
      var date = date.getTime();
      var date_e = new Date(0);
      date_e.setUTCSeconds(this.auth_user.exp);
      var date_e = date_e.getTime();
      //console.log(date_e);
      let parsed_data = JSON.parse(jsonPayload);
      this.current_id = parsed_data.userId;
      this.user_det(parsed_data.userId, 0);
      //console.log(parsed_data);
      this.user_id = parsed_data.userId;

      //console.log(date);
      if (date_e <= date) {
        var session_key = sessionStorage.getItem("session_key");
        axios({
          method: "POST",
          url: process.env.VUE_APP_ROOT_URL + "user/login",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            email: parsed_data.email,
            session_key: session_key,
          },
        })
          .then(function(response) {
            sessionStorage.clear();
            var token = `Bearer ${response.data.token}`;
            var session_key = response.data.session_key;
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("session_key", session_key);
          })
          .catch(function(error) {
            sessionStorage.clear();
            window.location.replace("login");
            //console.log(error);
          });
      }
    },
    logout() {
      sessionStorage.clear();
      window.location.replace("login");
    },
    genRandomIndex(length) {
      return Math.ceil(Math.random() * (length - 1));
    },
    user_det(id, x) {
      this.id = id;
      let url = process.env.VUE_APP_ROOT_URL + "user/one/" + this.id;
      var token = sessionStorage.getItem("token");
      var vueInstance = this;
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then(function(response) {
          vueInstance.user_info = response.data.message;
          vueInstance.email = response.data.message.email;
          vueInstance.name = response.data.message.name;
          vueInstance.surname = response.data.message.surname;
          vueInstance.clearance = response.data.message.clearance;
          if (x != 0) {
            vueInstance.show = true;
          } else {
            vueInstance.current_name =
              response.data.message.name + " " + response.data.message.surname;
          }
          let status = vueInstance.clearance;
          if (status == 0) {
            vueInstance.status1 = "Admin";
          } else if (status == 1) {
            vueInstance.status1 = "Manager";
          } else if (status == 2) {
            vueInstance.status1 = "Employee";
          } else {
            vueInstance.status1 = "Viewer";
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    add_user() {
      let url = process.env.VUE_APP_ROOT_URL + "user/singup";
      var token = sessionStorage.getItem("token");
      var vueInstance = this;
      if (this.status1 == "Viewer") {
        this.clearance1 = 3;
      } else if (this.status1 == "Employee") {
        this.clearance1 = 2;
      } else if (this.status1 == "Manager") {
        this.clearance1 = 1;
      } else {
        this.clearance1 = 0;
      }
      this.email1 = this.email1.toLowerCase();
      this.email1 = this.email1.replace(/\s+/g, "");
      axios({
        method: "POST",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: {
          name: this.name1,
          surname: this.surname1,
          email: this.email1,
          password: this.password1,
          clearance: this.clearance1,
        },
      })
        .then(function(response) {
          window.location.reload();
          
        })
        .catch(function(error) {
          console.log(error);
          vueInstance.dialog = true;
        });
    },

    sort_names() {
      this.names = [];
      this.surnames = [];
      this.user_list = [];

      for (let i = 0; i < this.all_users.length; i++) {
        this.names.push([
          this.all_users[i].name,
          this.all_users[i].email,
          this.all_users[i]._id,
          this.all_users[i].clearance,
        ]);
        this.surnames.push(this.all_users[i].surname);
      }
      for (let i = 0; i < this.all_users.length; i++) {
        this.user_list.push({
          name: this.all_users[i].name,
          surname: this.all_users[i].surname,
          email: this.all_users[i].email,
          _id: this.all_users[i]._id,
        });
      }
    },
    save_info() {
       
      var vueInstance = this;
      if (this.status1 == "Viewer") {
        this.clearance = 3;
      } else if (this.status1 == "Employee") {
        this.clearance = 2;
      } else if (this.status1 == "Manager") {
        this.clearance = 1;
      } else {
        this.clearance = 0;
      }
       for (let i = 0; i < this.all_users.length; i++) {
        if (this.all_users[i]._id == this.id) {
           this.all_users[i].name = this.name;
          this.all_users[i].surname = this.surname
          this.all_users[i].clearance = this.clearance
          this.sort_names();
        }
      }
      let url = process.env.VUE_APP_ROOT_URL + "user/update/" + this.id;
      var token = sessionStorage.getItem("token");
      this.email = this.email.toLowerCase();
      this.email = this.email.replace(/\s+/g, "");

      axios({
        method: "PATCH",
        url: url,
        headers: {
          Authorization: token,
        },
        data: {
          name: this.name,
          surname: this.surname,
          email: this.email,
          clearance: this.clearance,
        },
      })
        .then(function(response) {
          vueInstance.show = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    delete_usr() {
      for (let i = 0; i < this.all_users.length; i++) {
        if (this.all_users[i]._id == this.id) {
          this.all_users.splice(i, 1);
          this.sort_names();
        }
      }
      let url = process.env.VUE_APP_ROOT_URL + "user/delete/" + this.id;
      var token = sessionStorage.getItem("token");
      var vueInstance = this;
      axios({
        method: "DELETE",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then(function(response) {
          vueInstance.show = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    Getallusers() {
      let url = process.env.VUE_APP_ROOT_URL + "user/all";
      var token = sessionStorage.getItem("token");
      var key = sessionStorage.getItem("session_key");
      var vueInstance = this;
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then(function(response) {
          vueInstance.all_users = response.data.users;
          vueInstance.sort_names();
        })
        .catch(function(error) {
          console.log(error);
          vueInstance.allowed = false;
        });
    },
  },
};
</script>
